.wrap-box {
  display: flex;
  gap: 3rem;
  color: white;
  width: 100%;
  height: 200px;
  img {
    flex: 3;
    width: 300px;
    object-fit: cover;
  }
  .wrap-text {
    padding: 1rem 0;
    flex: 9;
    overflow: hidden;
    h3 {
      font-size: 40px;
      margin: 1rem 0;
      font-family: 'AntoniaH1';
    }
    p {
      font-family: 'Avenir ';
      font-size: 16px;
      height: 93px;
      overflow: hidden;
    }
    span {
      text-transform: uppercase;
      padding: 10px;
      width: fit-content;
      background-color: var(--wigram-blue-2);
    }
  }
  .time-article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    span {
      &:nth-child(1) {
        text-transform: uppercase;
        padding: 5px 10px;
        background-color: var(--wigram-blue-2);
      }
      &:nth-child(2) {
        margin-top: 7rem;
      }
    }
  }
}
.wrap-box-mobile {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: 'AntoniaText';
  color: white;
  padding: 1rem;
  background-color: var(--wigram-blue-3) !important;
  .text-country {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: 'AntoniaText';
    color: #ffffff;
  }
  .text-title {
    margin-top: 0.5rem;
    font-family: 'AntoniaText';
    color: #ffffff;
  }
  .wrap-text {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    color: rgb(65, 66, 79);
    h4 {
      text-transform: uppercase;
      color: #955c45;
      font-size: 0.7rem;
      font-family: Antonia;
    }
    span {
      font-family: Avenir;
      color: var(--wigram-blue-text);
      font-size: 0.7rem;
    }
  }
}
