.form-register__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sign-in {
    margin-left: 3px;
    color: var(--wigram-green-2);
    cursor: pointer;
  }
  .sign-in:hover {
    margin-left: 3px;
    color: var(--wigram-grey-0);
    cursor: pointer;
    text-decoration: underline;
  }
}
