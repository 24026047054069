.imagekit-img-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.imagekit-img,
.imagekit-img-macrowatch {
  position: relative;
  overflow: hidden;
  width: 101%;

  &::before {
    content: '';
    padding-top: 60%;
    width: 100%;
    display: block;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-height: 100%;
  }

  &.imagekit-img-square {
    width: 100%;

    &::before {
      padding-top: 100%;
    }

    img {
      max-width: fit-content;
      width: auto;
      height: 101%;
    }
  }
}
