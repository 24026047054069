.drag-item {
  position: relative;
  // background-color: var(--wigram-blue-2);
  .add-button {
    position: sticky;
    top: 0;
    z-index: 4999;
    background-color: var(--wigram-blue-2);
  }
  //   background-color: var(--wigram-blue-2);
  //   display: grid;
  //   grid-template-columns: 86px repeat(${filtersApplied.length}, 360px);
  // .date-cell {
  //   background-color: var(--wigram-blue-1);
  //   font-size: 0.6rem;
  //   font-family: Avenir, sans-serif;
  //   color: var(--wigram-blue-text);
  //   padding-top: 1rem;
  //   padding-bottom: 1rem;
  //   text-align: center;
  //   grid-column: 1/2;
  //   .day-of-month {
  //     font-size: 0.8rem;
  //     font-weight: 600;
  //   }
  // }

  .gap-date-cell {
    background-color: var(--wigram-blue-1);
    grid-column: 1/2;
    height: 20px;
  }

  // TODO: move to component
  .cards-wrapper {
    // border-right: 1px solid var(--wigram-blue-2);
    // padding: 1rem 0.7rem 1rem 0.7rem;
    .card {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: white;
      padding: 1rem;
      font-family: 'AntoniaText', georgia, serif;
      background-color: var(--wigram-blue-3);
      &.important {
        color: var(--wigram-grey-0);
        background-color: var(--wigram-green-1);
      }
    }
    &.no-padding {
      padding: 0px;
    }
  }

  .drag-item-container {
    // width: 360px;
  }
  .header-wrapper {
    position: sticky;
    top: 0;
    z-index: 4999;
    background-color: var(--wigram-blue-2);
    .header-cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 86px;
      border-right: 1px solid var(--wigram-blue-2);
      padding: 0 0.5rem 0 1rem;
      border-bottom: 1px solid var(--wigram-green-primary);
      flex-basis: 100%;
      h1 {
        cursor: pointer;
        margin: 0px;
        letter-spacing: 1px;
        color: var(--wigram-green-primary);
        font-size: 24px;
        font-weight: 700;
        font-family: AntoniaH1, 'AntoniaText', serif;

        .svg-wrapper {
          margin-left: 10px;
          position: relative;
        }

        svg {
          position: absolute;
          top: 6px;
          width: 12px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .body-wrapper {
    padding-top: 24px;
    border-right: 1px solid var(--wigram-blue-2);
  }
}
