.dateRange {
  font-family: 'Avenir' !important;
  &-title {
    padding: 1.5rem;
    background-color: var(--wigram-green-primary);
    width: 600px;
    display: flex;
    .dateRange-title-startDate {
      width: 50%;
      &-year {
        font-size: 20px;
        font-family: 'Avenir' !important;
      }
      &-date {
        font-size: 35px;
        font-family: 'Avenir' !important;
      }
    }
    .dateRange-title-endDate {
      &-year {
        font-size: 20px;
        font-family: 'Avenir' !important;
      }
      &-date {
        font-size: 35px;
        font-family: 'Avenir' !important;
      }
    }
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonthAndYearWrapper {
      width: 100%;
      z-index: 1;
      .rdrMonthAndYearPickers {
        display: flex;
        width: 100%;
        .rdrMonthPicker {
          width: 50%;
          display: flex;
          justify-content: center;
          font-size: x-large;
        }
        .rdrYearPicker {
          width: 50%;
          display: flex;
          justify-content: center;
          font-size: x-large;
        }
      }
    }
    .rdrMonths.rdrMonthsHorizontal {
      .rdrMonth {
        width: 50%;
        // margin-top: -60px;

        .rdrMonthName {
          display: flex;
          justify-content: center;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.7);
          display: none;
        }
        .rdrStartEdge,
        .rdrInRange,
        .rdrEndEdge,
        .rdrDayInPreview,
        .rdrDayStartPreview,
        .rdrDayEndPreview {
          color: var(--wigram-green-primary) !important;
        }
        .rdrDays,
        .rdrDayNumber {
          &:hover {
            border-color: var(--wigram-green-primary) !important;
            outline-color: var(--wigram-green-primary) !important;
          }
        }
        .rdrDayNumber {
          span {
            font-size: larger;
            &::after {
              background-color: var(--wigram-green-primary);
            }
          }
        }
      }
    }
  }
  &-btn {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    padding: 2rem;
    &-cal,
    &-submit {
      background-color: transparent;
      border: none;
      color: var(--wigram-green-primary);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
    }
    &-cal {
      margin-right: 2rem;
    }
  }
}
