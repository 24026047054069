.wrap-search-input {
  position: fixed;
  z-index: 5001;
  background: var(--wigram-blue-2);
  width: 100%;
  padding-right: 86px;
  overflow-y: scroll;
  height: 100vh;
  .search-input {
    position: fixed;
    left: 0;
    width: calc(100% - 17px);
    background-color: var(--wigram-green-primary);
    padding: 4.5rem 5rem 0.5rem 10.5rem;
    transition: all 0.2s ease-in-out;
    z-index: 5004;
    &.is-small {
      padding: 1rem 5rem 10px 10.5rem;
      .wrap-box-input {
        .search-icon {
          height: 25px;
        }
        .nosubmit {
          font-size: 24px;
          border-bottom: none;
          font-family: Avenir;
        }
        .input-close-icon {
          display: none;
        }
      }
      p,
      .bottom-wrap {
        display: none;
      }
      + .search-result {
        margin-top: 10rem;
      }
    }
    .wrap-box-input {
      position: relative;
      background-color: #4aa842;
      height: fit-content;
      .search-icon {
        position: absolute;
        cursor: pointer;
        left: 0rem;
        top: 1rem;
        transition: all 0.2s ease-in-out;
      }
      .nosubmit {
        border: none;
        border-bottom: 1px solid black;
        width: 100%;
        margin-right: 86px;
        height: 60px;
        line-height: 60px;
        background: transparent;
        font-size: xx-large;
        padding-left: 4rem;
        outline: none;
        font-weight: bold;
        transition: all 0.2s ease-in-out;
        &::-webkit-search-cancel-button {
          display: none;
        }
      }
      .input-close-icon {
        position: absolute;
        right: 0;
        bottom: 10px;
        width: 25px;
        transition: all 0.2s ease;
        cursor: pointer;
      }
    }
    p {
      padding-bottom: 5px;
      transition: all 0.2s ease;
    }
    .close-icon {
      position: absolute;
      cursor: pointer;
      top: 28px;
      right: 28px;
      width: 30px;
    }
    .bottom-wrap {
      margin-top: 3rem;
      width: 100%;
      transition: all 0.2s ease;
      .bottom-wrap-dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .blogs {
          position: relative;
          &:nth-child(1) {
            flex: 2;
          }
          &:nth-child(2) {
            flex: 3;
          }
          p {
            font-family: 'AntoniaText';
            font-size: 12px;
            cursor: pointer;
            .value {
              height: fit-content;
              margin-bottom: -10px;
              width: 100px;
              overflow: hidden;
              position: relative;
              display: inline-block;
              padding: 0 5px 0 5px;
              text-align: center;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .type-page {
            margin-left: 10px;
          }
          .drop-down-blogs {
            width: 13vw;
            background-color: white;
            position: absolute;
            top: 51px;
            padding: 1rem;
            overflow: auto;
            @media only screen and (max-width: 1200px) {
              width: 17vw;
            }
            @media only screen and (max-width: 768px) {
              width: 25vw;
            }
            .item-blog {
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .select-item {
              width: 100%;
              .all-actions {
                z-index: 1;
                color: #4aa842;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .container-page {
    position: relative;
    width: 100%;
    padding: 5rem 5rem 5rem 10.5rem;
    margin-top: 250px;
    .page-detail {
      background: transparent;
      width: 100%;
      color: white;
      display: flex;
      flex-direction: column;
    }
    .spinner {
      width: 100%;
      text-align: center;
    }
  }
}
.disabled-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
  .search-input {
    width: 100%;
  }
}
