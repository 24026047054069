.live-page {
  display: flex;
  background-color: var(--wigram-blue-2);
  .right-side {
    width: 86px;
    background-color: var(--wigram-blue-1);
    position: sticky;
    left: 0;
    z-index: 5001;
    .rs-content {
      .date-cell {
        background-color: var(--wigram-blue-1);
        font-size: 0.6rem;
        font-family: Avenir, sans-serif;
        color: var(--wigram-blue-text);
        text-align: center;
        grid-column: 1/2;
        .day-of-month {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }
    .group-icon {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      z-index: 2;
      padding-bottom: 20px;
      background-color: var(--wigram-blue-1);
      width: 78px;
      img {
        width: 32px;
        height: 80px;
      }
      img:first-child {
        height: auto;
        margin: 20px 0px;
      }
    }
  }
  .right-arrow {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 86px;
    height: 86px;
    z-index: 5000;
    background-color: var(--wigram-green-primary);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
