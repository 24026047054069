.login-container {
  @media only screen and (max-width: 768px) {
    grid-template-columns: 0fr 4fr;
  }
  grid-template-columns: 6fr 4fr;
  height: 100%;
  display: grid;
  transition: 500ms;
  .left-container {
    background-image: url('../../images/login_image.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    // display: grid;
    .logo {
      @media only screen and (max-width: 768px) {
        // grid-template-columns: 0fr 4fr;
        display: none;
      }
      margin-left: 11.9%;
      margin-top: 3.56%;
    }
  }
  .right-container {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .form-container {
      @media only screen and (max-width: 768px) {
        padding: 0px 15%;
      }
      width: 100%;
      transform: translate(100%, -50%);
      opacity: 0;
      position: absolute;
      left: 0;
      top: 50%;
      padding: 0px 20%;
      // z-index: 0;
      h1 {
        font-family: Antonia H1;
        font-style: normal;
        font-weight: 600;
        // font-size: 53.3333px;
        line-height: 110%;
        /* identical to box height, or 59px */

        letter-spacing: -0.01em;

        /* Text/Grey 2 */

        color: var(--wigram-grey-0);
        margin-bottom: 2rem;
      }
      h2 {
        font-family: Antonia H1;
        font-style: normal;
        font-weight: 600;
        // font-size: 53.3333px;
        line-height: 110%;
        /* identical to box height, or 59px */

        letter-spacing: -0.01em;

        /* Text/Grey 2 */

        color: var(--wigram-grey-0);
        margin-bottom: 2rem;
      }
      h3 {
        font-family: Antonia H3;
        font-style: normal;
        font-weight: normal;
        font-size: 0.7rem;
        line-height: 120%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 0.7rem;
        color: var(--wigram-grey-0);
      }
      .sign-in-button {
        background: var(--wigram-green-primary);
        border-radius: 2px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 40px 0 16px 0;
        padding: 0.7rem 1rem;
        /* height: 3rem; */
        border: 0;
        width: 100%;
        color: var(--wigram-grey-0);
        font-family: Antonia H3;
        font-style: normal;
        font-weight: 600;
        // font-size: 1.15rem;
        cursor: pointer;
      }
    }
    .justify-center {
      justify-content: center !important;
    }

    .button-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 1rem;
      cursor: pointer;
      color: var(--wigram-grey-0);

      .button-wrap {
        padding-bottom: 0.5rem;
        padding-bottom: 0.1rem;
        border-bottom: 2px solid var(--wigram-grey-0);
        span {
          font-family: 'AntoniaText';
          font-style: normal;
          font-weight: normal;
          font-size: 0.85rem;
          line-height: 160%;
          /* or 22px */

          /* Text/Grey 2 */

          color: var(--wigram-grey-0);
        }
      }
      .button-wrap:hover {
        border-bottom-color: var(--wigram-green-2) !important;
        span {
          color: var(--wigram-green-2) !important;
        }
      }
    }
  }
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
.active-form {
  transform: translate(0, -50%) !important;
  opacity: 1 !important;
  transition: 500ms;
  z-index: 2;
  animation: login 500ms normal;
}

@keyframes login {
  0% {
    transform: translate(100%, -50%);
  }
  50% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(0%, -50%);
  }
}
