.intro-container {
  h3 {
    font-family: 'AntoniaH3', georgia, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    /* or 26px */
    color: #000000;
  }
  p {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */
    color: #6A6A6A;
    margin-top: 0.7rem;
  }
}
