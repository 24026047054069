.group-by {
  position: absolute;
  top: 86px;
  left: 0;
  width: 86px;
  display: flex;
  background-color: var(--wigram-blue-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  .current-group-by {
    padding-left: 1rem;
    flex-grow: 1;
    font-size: 0.7rem;
    color: var(--wigram-blue-text);
    line-height: 30px;
    cursor: pointer;
  }
  svg {
    width: 12px;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.group-by-dropdown {
  background-color: var(--wigram-green-primary);
  padding: 0.5rem 1rem;
  position: absolute;
  top: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.3rem;
  color: var(--wigram-grey-0);
  width: 150px; // Same width as grid column
  div {
    cursor: pointer;
  }
  .current {
    color: var(--wigram-green-text-disabled-2);
    pointer-events: none;
    cursor: default;
  }
}
