.article-title-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  .article-title {
    width: fit-content;
    display: flex;
    h3 {
      padding: 8px 14px;
      font-style: normal;
      // font-weight: 600;
      font-size: 0.7rem;
      line-height: 110%;
      font-family: 'AntoniaText', georgia, serif;
      //   display: flex;
      align-items: center;
      // letter-spacing: 0.2em;
      color: #ffffff;
      text-transform: uppercase;
      background-color: #000000;
    }
    .macro-watch {
      color: #000000;
      background-color: #ffffff;
    }
  }
  .article-flex {
    display: flex;
    flex-direction: row;
    .article-description {
      flex: auto;
      width: 82%;
      display: flex;
      align-items: center;
      h1 {
        padding: 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 110%;
        font-family: 'AntoniaText', georgia, serif;
        /* identical to box height, or 20px */
        color: #000000;
        background-color: var(--wigram-green-primary);
        text-align: left;
        // font-weight: 600;
      }
    }
    .article-date {
      display: flex;
      width: 16%;
      span {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 110%;
        /* identical to box height, or 11px */
        display: flex;
        align-items: center;
        color: #ffffff;
        justify-content: center;
        position: absolute;
        right: 16px;
        bottom: 14px;
      }
    }
  }
}
