.right-side {
  .rs-content {
    width: 86px;
    margin-top: 116px;
    .date-cell {
      &__day {
        color: var(--wigram-green-2);
        font-size: 1.2rem;
        font-family: 'AntoniaText', georgia, serif;
        padding-bottom: 0px !important;
        line-height: 1.3rem;
        font-weight: 500;
      }
      &__month {
        text-transform: uppercase;
        color: var(--wigram-green-2);
        font-size: 0.7rem;
        font-family: 'AntoniaText', georgia, serif;
        line-height: 1.05rem;
        letter-spacing: 0.5px;
      }
    }
  }
}
