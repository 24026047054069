.button-scroll-top-component {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3000;

  .button-scroll-top-component-inner {
    width: 60px;
    height: 60px;
    background: var(--wigram-green-primary);
    color: var(--wigram-grey-0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    cursor: pointer;

    &.is-show {
      opacity: 1;
    }

    svg {
      margin-top: 6px;
    }

    @media only screen and (min-width: 768px) {
      width: 86px;
      height: 86px;
    }
  }
}
