@font-face {
  font-family: 'AntoniaH1';
  src: local('AntoniaH1'),
    url('./fonts/AntoniaH1-Regular.woff2') format('woff2'),
    url('./fonts/AntoniaH1-Regular.woff') format('woff'),
    url('./fonts/AntoniaH1-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AntoniaH2';
  src: local('AntoniaH2'),
    url('./fonts/AntoniaH2-Regular.woff2') format('woff2'),
    url('./fonts/AntoniaH2-Regular.woff') format('woff'),
    url('./fonts/AntoniaH2-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AntoniaH3';
  src: local('AntoniaH3'),
    url('./fonts/AntoniaH3-Regular.woff2') format('woff2'),
    url('./fonts/AntoniaH3-Regular.woff') format('woff'),
    url('./fonts/AntoniaH3-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AntoniaText';
  src: local('AntoniaText'),
    url('./fonts/AntoniaText-Regular.woff2') format('woff2'),
    url('./fonts/AntoniaText-Regular.woff') format('woff'),
    url('./fonts/AntoniaText-Regular.ttf') format('truetype');
}

// Customise Bulma variables to match Wigram theme
$input-background-color: #f1f1f1;
$input-border-color: #f1f1f1;
$input-placeholder-color: #555555;
$input-focus-border-color: #777;
$input-focus-box-shadow-color: #ccc;

$radius: 0px;
$input-radius: 2px;
$family-primary: AntoniaText;

// Box
$box-radius: 0px;
$box-shadow: 0px;

// Import Bulma styles and use above variables
@import './node_modules/bulma/bulma.sass';

.input {
  height: 3rem;
}

.link-underlined {
  border-bottom: 1px solid #000;
  color: #333;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Prevent overscroll - https://stackoverflow.com/questions/12046315/prevent-overscrolling-of-web-page
html {
  overflow: auto;
  // Adjusting the 'overflow' property may cause the scroll events to not fire.
  height: 100%;
  overscroll-behavior: none; // Prevent over scrolling page with mouse wheel
}

.hidden-scroll {
  overflow: hidden !important;
}

body,
.page,
#__next,
#root,
.App {
  // #root is used by storybook
  height: 100%;
}

body {
  display: block;
  min-width: 100%;

  background-color: var(--wigram-blue-1);
  font-family: 'AntoniaText', georgia, serif;
  font-size: 14px;
  color: var(--wigram-grey-0);

  .mt-2 {
    margin-top: 16px;
  }
  .mr-2 {
    margin-right: 16px !important;
  }
  .ml-2 {
    margin-left: 60px !important;
  }

  .ml-180px {
    margin-left: 180px !important;
  }

  h1 {
    font-family: AntoniaH1, 'AntoniaText';
    font-size: 60px;
    line-height: 110%;
  }

  h2 {
    font-family: AntoniaH2, 'AntoniaText', serif;
    font-size: 40px;
    line-height: 120%;
  }

  h3 {
    font-family: 'AntoniaH3', georgia, serif;
    font-size: 18px;
    line-height: 120%;
  }

  p {
    font-size: 1rem;
    font-family: 'AntoniaText', georgia, serif;
    line-height: 1.8rem;
    padding-bottom: 2.5rem;
    white-space: pre-wrap; // maintains line breaks set in CMS
  }
}

//
// Amazon Amplify Auth Styles
//

:root {
  --amplify-primary-color: #333333;
  --amplify-primary-tint: #000000;
  --amplify-primary-shade: #000000;
}

amplify-authenticator {
  background-image: url('./images/login-background.jpg');
  width: 100%;
}

amplify-sign-in {
  background-color: pink;
}

// Grid layout

// 12 columns grid
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  grid-gap: 30px;
  padding: 0px 60px; // gutters
  grid-template-areas: 'l l l  c c c c c c . r r';

  .grid-left {
    grid-area: l;
  }
  .grid-center {
    grid-area: c;
  }
  .grid-right {
    grid-area: r;
  }
}

// Backgrounds
.dark-bg {
  background-color: #02060a26;
}

.fullpage-section {
  .extra-padding {
    padding-top: 8rem;
    padding-bottom: 14rem;
  }
}

.group {
  margin-top: 5rem;
}

// Custom Colors
:root {
  --wigram-green-0: #3f8f39; // Green 4
  --wigram-green-1: #4aa842; // Green 5 (primary)
  --wigram-green-primary: var(--wigram-green-1);
  --wigram-green-2: #58bf4f; // Green 6
  --wigram-green-3: #e3efdd; // Green 8
  --wigram-green-text-disabled: #1f5e1a; // Displayed at 0.5 opacity. 1 opacity color = #42843C;
  --wigram-green-text-disabled-2: #42843c;

  --wigram-grey-0: #222222; // Grey 2
  --wigram-grey-1: #444444; // Grey 3
  --wigram-grey-2: #666666; // Grey 4
  --wigram-grey-3: #999999; // Grey 5
  --wigram-grey-4: #aaaaaa; // Grey 6
  --wigram-grey-5: #eeeeee; // Grey 8
  --wigram-grey-6: #f7f7f9; // Grey 9
  --wigram-grey-7: #fafafa; // Grey 10

  --wigram-blue-1: #18212d;
  --wigram-blue-2: #1c2633;
  --wigram-blue-3: #233246;
  --wigram-blue-text: #527392;
  --wigram-blue-hyperlink: #5b85d7;
  --wigram-black-0: #000000;

  // Amplify
  --amplify-primary-color: var(--wigram-green-primary);
  --amplify-primary-contrast: var(--wigram-grey-0);
  --amplify-font-family: 'AntoniaText';
  --amplify-primary-shade: var(--wigram-green-0);
}

amplify-container {
  // background-image: url('https://ik.imagekit.io/wigramdev/login_background_a42668c7dd.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: white;
  amplify-authenticator {
    background-image: none;
  }
  amplify-sign-in {
    background-color: transparent !important;
  }
}

amplify-sign-in {
  color: yellow;
}

// Allows for a 50px channel/gap that can be used for the side navigation
.with-side-nav {
  margin-left: 86px !important;
}

.display-inline {
  display: flex;
  flex-direction: row;
}

.display-inline-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiCircularProgress-colorPrimary {
  color: #3f8f39 !important;
}

.page-title {
  font-size: 40px;
  line-height: 48px;
  font-family: AntoniaH3, 'AntoniaText', serif;
  font-weight: 400;
  @media only screen and (min-width: 768px) {
    font-size: 60px;
    line-height: 72px;
  }

  &.page-title-small {
    font-size: 32px;
    color: #ffffff;
    line-height: 2;
    letter-spacing: -1px;

    @media only screen and (min-width: 768px) {
      font-size: 40px;
      margin-top: 3rem;
    }
  }
}

// loader

.loader,
.loader:after {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
.loader {
  margin: 0px auto;
  font-size: 1.05rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-right: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-left: 0.25rem solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.markdown-custom {
  ol {
    li {
      list-style-type: inherit;
      line-height: 1.8em;
      padding-bottom: 1.8em;
    }
  }
  ul {
    li {
      list-style-type: initial;
      line-height: 1.8em;
      padding-bottom: 1.8em;
    }
  }

  li {
    margin-left: 32px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
}

.shortform-page {
  .markdown-custom {
    ul > li,
    ol > li {
      padding-bottom: inherit;
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-tooltip-class {
    min-width: 100% !important;
    top: unset !important;
    position: fixed !important;
    bottom: 0px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    // height: 35%;
    padding-bottom: 60px !important;
  }
  .intro-container {
    h3 {
      font-family: AntoniaH1, 'AntoniaText' !important ;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 1.875rem !important;
      line-height: 220% !important;
    }
    p {
      font-family: Avenir !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 0.875rem !important;
      line-height: 120% !important;
      margin-right: 22% !important;
      color: #6a6a6a !important;
    }
  }
  .introjs-tooltipbuttons {
    padding-bottom: 50px !important;
  }
}
.custom-tooltip-class {
  width: 400px;
  max-width: 400px !important;
  padding-bottom: 10px;
  .introjs-tooltip-header {
    .introjs-skipbutton {
      display: block;
      background-image: url(./images/step-close.svg);
      background-color: transparent;
      color: transparent;
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0;
    }
  }
  .introjs-tooltiptext {
    padding: 0.5rem 20px 0.5rem 20px;
  }
  .introjs-bullets ul li {
    margin: 0 5px;
  }
  .introjs-bullets ul li a.active {
    background: var(--wigram-green-primary);
    width: 6px;
  }
  .introjs-tooltipbuttons {
    border-top: 0px solid #e0e0e0;
    padding: 0px 10px;
    text-align: right;
    white-space: nowrap;
    position: absolute;
    bottom: 10px;
    width: 100%;
    .introjs-prevbutton {
      display: block;
      background-image: url(./images/step-left-arrow.svg);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      color: transparent;
      box-shadow: none;
      border: 0;
      width: 24px;
      height: 22px;
    }
    .introjs-nextbutton {
      display: block;
      background-image: url(./images/step-right-arrow.svg);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      color: transparent;
      box-shadow: none;
      border: 0;
      width: 24px;
      height: 22px;
    }
  }
}
.without-box-shadow {
  box-shadow: #212121cc 0px 0px 0px 0px, #21212180 0px 0px 0px 5000px !important;
}
.l-0 {
  left: 0 !important;
}
.is-disabled {
  pointer-events: none !important;
}
