.input-custom {
  position: relative;
  background: #e3e2de;
  box-shadow: inset 2.66667px 2.66667px 5.33333px #0000001a;
  border-radius: 2.66667px;
  outline: none;
  border: 0;
  /* height: 64px; */
  width: 100%;
  color: var(--input-placeholder-color);
  padding: 0.7rem 1rem;
  /* height: 3rem; */
  font-size: 1.05rem;
  font-family: Avenir;
  font-weight: 400;
}
.error-text {
  color: #ff0000da;
  margin-bottom: 0;
  padding-bottom: 0;
}

.invalid {
  border: 1px solid #ff0000da;
}

.input-wraper {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 0px;
  z-index: 2;
  top: 50%;
  transform: translate(-20%, -50%);
  height: 38px;
  width: 38px;
  cursor: pointer;
}
