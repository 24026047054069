.tutorial-container {
  position: absolute;
  z-index: 5005;
  width: 100vw;
  height: 100vh;
  transition: 0ms;
}
.active-tutorial {
  animation-delay: 0.25s;
  animation: translateAnimate 1s forwards;
}
.active-ismobile{
  animation-delay: 0.25s;
  animation: translateAnimateMobile 1s forwards;
}
@keyframes translateAnimateMobile {
  from {
    transform: translateY(120vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes translateAnimate {
  from {
    transform: translateY(120vh);
  }
  to {
    transform: translateY(40vh);
  }
}

.active-tutorial-container {
  animation: opacityAnimate 1.5s forwards;
}

@keyframes opacityAnimate {
  from {
    background-color: #00000000;
  }
  to {
    background-color: #00000080;
  }
}
